








import { Component, Vue } from 'vue-property-decorator';
import UserNotifications from "@/components/user/profile/UserNotifications.vue";

@Component({
  components: {UserNotifications}
})
export default class UnreadWorkspaceNotification extends Vue {
}
